import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import { Link, graphql } from "gatsby";
import Card from "components/card";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "used"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/others/used.png"}) { ...eyecatchImg },
    ccpuhikaku: file(relativePath: { eq: "cover/cpu/cpu-hikaku.png"}) { ...eyecatchImg },
    cgrabohikaku: file(relativePath: { eq: "cover/gpu/grabo-hikaku.png"}) { ...eyecatchImg },
    cusedshop: file(relativePath: { eq: "cover/rank/usedshop.png"}) { ...eyecatchImg },
    coffice: file(relativePath: { eq: "cover/pcparts/office.PNG"}) { ...eyecatchImg },
    digital_dragon: file(relativePath: { eq: "where/digital_dragon.png"}) { ...normalImg },
    junk_world: file(relativePath: { eq: "where/junk_world.png"}) { ...normalImg },
    qualit: file(relativePath: { eq: "where/qualit.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`中古パソコンの危険性は？セキュリティリスクとその対策`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="中古パソコンの危険性は？" mdxType="Image" />
    <p>{`中古パソコンを買うとなるとまず漠然とした不安を抱えるかもしれない。外傷のスレなど分かりやすい部分は良いが、問題は中身である。ウイルスが仕込まれていないか？Windows OSが古いがセキュリティの不安は？などである。`}</p>
    <p>{`そこで本記事では、ソフトウェアエンジニアの管理人が中古パソコンのセキュリティリスクとその対策について解説し、安心して中古パソコンを購入できることを目指す。`}</p>
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "Windowsのサポート期間は問題がないか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Windows%E3%81%AE%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E6%9C%9F%E9%96%93%E3%81%AF%E5%95%8F%E9%A1%8C%E3%81%8C%E3%81%AA%E3%81%84%E3%81%8B%EF%BC%9F",
        "aria-label": "Windowsのサポート期間は問題がないか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windowsのサポート期間は問題がないか？`}</h2>
    <p>{`中古パソコンのセキュリティ問題を担保する上で考えなければならないものがWindows OSのサポート期間であり、OSのサポート期限切れが近づく度に量販店などでは催促キャンペーンが行われ買い替えを迫られる。`}</p>
    <p>{`Windows PCは定期的に自動でOSのアップデートが行われ、セキュリティが保たれている。ところがこの`}<strong parentName="p"><em parentName="strong">{`Windows Updateが行なわれるサポート期間はOSの登場からおよそ10年`}</em></strong>{`となっており、期限を過ぎるとアップデートが提供されなくなってしまう。`}</p>
    <p>{`直近のOSであるとサポート期限は次のようになっている。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>OSの種類</th>
      <th>サポート開始日</th>
      <th>サポート終了日</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Windows 10</td>
      <td>2015年7月29日</td>
      <td>2025年10月14日</td>
    </tr>
    <tr>
      <td>Windows 11</td>
      <td>2021年10月4日</td>
      <td>2031年xx月x日</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`サポート期間が切れたらセキュリティの保証はされないのでOSをUpdateするかパソコンをOSが新しいものに買い替えるようマイクロソフトのホームページやメディアなどでアナウンスが行われる。`}</p>
    <p>{`ただ`}<strong parentName="p"><em parentName="strong">{`OSのアップデートにどれほど真面目に対応しなければならないかは意見が分かれる`}</em></strong>{`ところであり、企業によってはサポート期限切れのOSをいつまでも使い続けている例も多い。これに対して一つの基準を示したい。`}</p>
    <h3 {...{
      "id": "サポート期限が切れたら直ちに危険という訳ではない",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E6%9C%9F%E9%99%90%E3%81%8C%E5%88%87%E3%82%8C%E3%81%9F%E3%82%89%E7%9B%B4%E3%81%A1%E3%81%AB%E5%8D%B1%E9%99%BA%E3%81%A8%E3%81%84%E3%81%86%E8%A8%B3%E3%81%A7%E3%81%AF%E3%81%AA%E3%81%84",
        "aria-label": "サポート期限が切れたら直ちに危険という訳ではない permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`サポート期限が切れたら直ちに危険という訳ではない`}</h3>
    <p>{`まず一般的な話として`}<strong parentName="p"><em parentName="strong">{`ソフトウェアの不具合は公開時に一番多く見つけられるもの`}</em></strong>{`である。それまでは内部の開発者やテスターなどごく少人数しかソフトウェアを使っていないため、一般公開時に不具合が大量に出るのは当然である。`}</p>
    <p>{`しかし10年が経過し`}<strong parentName="p"><em parentName="strong">{`サポート期限を迎えたOSは多くのユーザによって鍛えられたもので動作は安定し、セキュリティは強固なものになっている`}</em></strong>{`。発売したばかりのOSの方が確実にリスクは高い。`}</p>
    <p>{`よってパソコン業界は不安を煽ってパソコンの新規購入を促すだろうが、`}<strong parentName="p"><em parentName="strong">{`直ちに対応すべきということにはならない`}</em></strong>{`。`}</p>
    <p>{`このパソコン業界の理屈が通るならば、Microsoft Officeのサポート期限は５年のため、ほとんどの人がサポート切れのオフィスを使い脅威に晒されており、３年ぐらいで新品パソコンを買い替える必要が出てくるのだが、こちらはとやかく言われないのは不思議である。`}</p>
    <h3 {...{
      "id": "リスクがあればサポート期限切れでもセキュリティパッチが提供される",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%AA%E3%82%B9%E3%82%AF%E3%81%8C%E3%81%82%E3%82%8C%E3%81%B0%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E6%9C%9F%E9%99%90%E5%88%87%E3%82%8C%E3%81%A7%E3%82%82%E3%82%BB%E3%82%AD%E3%83%A5%E3%83%AA%E3%83%86%E3%82%A3%E3%83%91%E3%83%83%E3%83%81%E3%81%8C%E6%8F%90%E4%BE%9B%E3%81%95%E3%82%8C%E3%82%8B",
        "aria-label": "リスクがあればサポート期限切れでもセキュリティパッチが提供される permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`リスクがあればサポート期限切れでもセキュリティパッチが提供される`}</h3>
    <p>{`また、`}<strong parentName="p"><em parentName="strong">{`多くの人がサポート切れのOSを使い続けているという現状で、マイクロソフトがセキュリティ対策を取らないということは大企業の信頼性を考えると有り得ない`}</em></strong>{`ことである。`}</p>
    <p>{`実例をあげよう。2017年に登場したWannaCryというランサムウェアのウイルス、すなわちパソコン画面をロックして、ロックを解除する代わりに身代金を要求するというウイルスが世界的に猛威をふるった事があった。`}</p>
    <p>{`これが医療機関などにも広まりを見せたため、これを防止するためマイクロソフトは既にサポートが終了して数年が経過していたWindows XPに対してもセキュリティパッチを配布している。`}</p>
    <p>{`新規機能の開発がサポート切れのOSで提供されることはないが、`}<strong parentName="p"><em parentName="strong">{`十分に使用者が減るまでは、少なくともサポート切れから数年はセキュリティパッチ対策が提供されると考えても良い`}</em></strong>{`だろう。`}</p>
    <h3 {...{
      "id": "Windows-Defenderのサポート期限切れ対策",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Windows-Defender%E3%81%AE%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E6%9C%9F%E9%99%90%E5%88%87%E3%82%8C%E5%AF%BE%E7%AD%96",
        "aria-label": "Windows Defenderのサポート期限切れ対策 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows Defenderのサポート期限切れ対策`}</h3>
    <p>{`WindowsにはWindows Defenderというフリーのアンチウイルスソフトが付属する。ところが`}<strong parentName="p"><em parentName="strong">{`Windows OSのサポート期間を過ぎるとOSの移行を促すために、Windows Defenderが更新を辞めてしまう`}</em></strong>{`のである。`}</p>
    <p>{`OSがいくら強固でも、ユーザに使用を許諾を得た上で欺くソフトウェアには抗えないため、フリーの怪しいソフトを色々使って遊びたいような人にとっては打撃が大きいかもしれない。`}</p>
    <p>{`しかしこれには明確な対策が存在し、`}<strong parentName="p"><em parentName="strong">{`不安な人はフリーソフトの`}<a parentName="em" {...{
            "href": "https://www.avast.co.jp/free-antivirus-download#pc",
            "target": "_blank",
            "rel": "nofollow noopener"
          }}>{`アバスト無料アンチウイルス`}</a>{`を入れておけば良い`}</em></strong>{`。`}</p>
    <p>{`Avastの他`}<strong parentName="p"><em parentName="strong">{`多くのアンチウイルスはOSの移行期間に対応するため、追加で10年などかなり長期間古いOSをサポートする`}</em></strong>{`。`}</p>
    <h3 {...{
      "id": "個人で使う分にはサポート切れから３年程度は猶予があると思ってよい",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%80%8B%E4%BA%BA%E3%81%A7%E4%BD%BF%E3%81%86%E5%88%86%E3%81%AB%E3%81%AF%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E5%88%87%E3%82%8C%E3%81%8B%E3%82%89%EF%BC%93%E5%B9%B4%E7%A8%8B%E5%BA%A6%E3%81%AF%E7%8C%B6%E4%BA%88%E3%81%8C%E3%81%82%E3%82%8B%E3%81%A8%E6%80%9D%E3%81%A3%E3%81%A6%E3%82%88%E3%81%84",
        "aria-label": "個人で使う分にはサポート切れから３年程度は猶予があると思ってよい permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`個人で使う分にはサポート切れから３年程度は猶予があると思ってよい`}</h3>
    <p>{`さらにウイルス対策ソフトだけではなく、Google ChromeなどのブラウザもWindowsのサポート期限切れ後も旧OSでのアップデートを３年程度は続けてくれるため、ブラウザレベルでもセキュリティは担保される。`}</p>
    <p>{`OSが脆弱でもブラウザがしっかりしていれば、OSの脆弱性を突く攻撃をブラウザが防げるわけである。さらにウイルス対策ソフトもあれば２重に脅威から守ることができる。`}</p>
    <p>{`以上の考察からWindowsのサポート期間の結論として、`}<strong parentName="p"><em parentName="strong">{`サポート切れの後３年程度は使い続けても全く問題は無い`}</em></strong>{`であろう。`}</p>
    <p>{`それ以上でもウイルス対策ソフトがあればセキュリティ上はそれほど問題はないが、`}<strong parentName="p"><em parentName="strong">{`様々なアプリケーションが古いOSには対応しなくなり、使い勝手が悪くなる`}</em></strong>{`。`}</p>
    <p>{`ただし、企業の場合は個人とは比較にならないほど積極的に攻撃を受け、かつ一人の漏洩から他の社員のパソコンにも影響が出る可能性があるので、あくまで個人で使う場合であると理解しよう。`}</p>
    <h3 {...{
      "id": "OSレベルのセキュリティより、セキュリティ意識が重要",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#OS%E3%83%AC%E3%83%99%E3%83%AB%E3%81%AE%E3%82%BB%E3%82%AD%E3%83%A5%E3%83%AA%E3%83%86%E3%82%A3%E3%82%88%E3%82%8A%E3%80%81%E3%82%BB%E3%82%AD%E3%83%A5%E3%83%AA%E3%83%86%E3%82%A3%E6%84%8F%E8%AD%98%E3%81%8C%E9%87%8D%E8%A6%81",
        "aria-label": "OSレベルのセキュリティより、セキュリティ意識が重要 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OSレベルのセキュリティより、セキュリティ意識が重要`}</h3>
    <p>{`最後に余談ではあるが、Windows Updateによる脆弱性の改善など全体的なリスクと比べるとごく僅かであり、セキュリティ意識を持つ方が遥かに重要である。`}</p>
    <p>{`仮にセキュリティホールがOSに見つかったとしても、怪しいソフトやEmailの添付ファイルを実行するなどしない限り基本的にパソコンは守られるわけである。`}</p>
    <p>{`また、情報漏洩としては偽サイトにパスワードを入力するなどOSのセキュリティ対策以外のところで破られる事がほとんどであり、`}<strong parentName="p"><em parentName="strong">{`OSの脆弱性をつく高度な攻撃により個人情報が盗まれるなど滅多にあるものではない`}</em></strong>{`。`}</p>
    <p>{`期限切れだからといって慌てて買い替えるよりは`}<strong parentName="p"><em parentName="strong">{`自身のセキュリティ意識がパソコンと個人情報を守る`}</em></strong>{`のである。`}</p>
    <h2 {...{
      "id": "Windows-11はIntel第８世代以降のCPUが必要",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Windows-11%E3%81%AFIntel%E7%AC%AC%EF%BC%98%E4%B8%96%E4%BB%A3%E4%BB%A5%E9%99%8D%E3%81%AECPU%E3%81%8C%E5%BF%85%E8%A6%81",
        "aria-label": "Windows 11はIntel第８世代以降のCPUが必要 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows 11はIntel第８世代以降のCPUが必要`}</h2>
    <p>{`セキュリティ対策が必要なのはOSだけではなく、土台となるCPUやマザーボードなどのハードウェア、その上で動くOS、さらにその上で動くブラウザなどのアプリケーションとそれぞれのレイヤーで対策をする必要がある。`}</p>
    <p>{`Windows 11は脆弱性対策の観点から`}<a parentName="p" {...{
        "href": "https://learn.microsoft.com/ja-jp/windows-hardware/design/minimum/supported/windows-11-supported-intel-processors",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`サポートするCPU`}</a>{`をを定めており、`}<strong parentName="p"><em parentName="strong">{`Intel Coreシリーズならば第８世代以降、AMDのRyzenならば2000以降のCPUをサポートする`}</em></strong>{`。これについては専門性がやや高いが`}<a parentName="p" {...{
        "href": "https://ascii.jp/elem/000/004/061/4061479/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`ASCIIの記事`}</a>{`
も参考になる。`}</p>
    <p>{`世代というのはCPUのアーキテクチャが何代目かということを示しており、基本的には毎年１回刷新されて型番が上がっていく。第８世代の型番は例えばCore i5 8200など8XXXで表されており、それより大きい番号ならばセキュリティの観点から問題がないだろう。`}</p>
    <p>{`一方で中古パソコンとして`}<strong parentName="p"><em parentName="strong">{`第７世代以下のCPUをWindows 11にアップグレードして販売している例を見るがやや危険といえる`}</em></strong>{`。`}</p>
    <p>{`もっとも個人レベルのセキュリティで問題となるとは思えないが、マイクロソフトによる動作保証もされていないため、購入当時は動いていたとしても、`}<strong parentName="p"><em parentName="strong">{`今後のWindowsアップデートなどで問題になる可能性は十分にあると考えられる`}</em></strong>{`。`}</p>
    <p>{`ちなみにWindows 10から11へのアップグレードは`}<a parentName="p" {...{
        "href": "https://support.microsoft.com/ja-jp/windows/windows-11-%E3%82%92%E3%82%A4%E3%83%B3%E3%82%B9%E3%83%88%E3%83%BC%E3%83%AB%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95-e0edbbfb-cfc5-4011-868b-2ce77ac7c70e",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`windows-11-をインストールする方法`}</a>{`などを参考にして無償で行えるが、その場合もCPUがサポート対象かは確認する必要がある。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`第７世代以下はWindows 10を使い続ける事がおすすめ`}</em></strong>{`である。`}</p>
    <h2 {...{
      "id": "中古パソコンにウイルスの危険はあるのか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AB%E3%82%A6%E3%82%A4%E3%83%AB%E3%82%B9%E3%81%AE%E5%8D%B1%E9%99%BA%E3%81%AF%E3%81%82%E3%82%8B%E3%81%AE%E3%81%8B%EF%BC%9F",
        "aria-label": "中古パソコンにウイルスの危険はあるのか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`中古パソコンにウイルスの危険はあるのか？`}</h2>
    <p>{`まずウイルスとは何となく目に見えず得体の知らないものというイメージであるが、`}<strong parentName="p"><em parentName="strong">{`コンピュータの世界でウイルスは悪意を働くただのプログラム`}</em></strong>{`である。`}</p>
    <p>{`作成は簡単で、例えば開くとデスクトップにあるテキストファイルを全て抽出して、それを特定のサーバに送り個人情報を盗み取るというプログラムは、作ろうと思えばWindowsアプリを作成したことがない管理人であっても３日もあれば作れるだろう。`}</p>
    <p>{`あるいはより分かりにくい例として、目に見えないがバックグラウンドで動く常駐アプリというものを作れば、人目に触れず情報を盗むことができる。`}</p>
    <h3 {...{
      "id": "メルカリでの購入は危険性が高い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A1%E3%83%AB%E3%82%AB%E3%83%AA%E3%81%A7%E3%81%AE%E8%B3%BC%E5%85%A5%E3%81%AF%E5%8D%B1%E9%99%BA%E6%80%A7%E3%81%8C%E9%AB%98%E3%81%84",
        "aria-label": "メルカリでの購入は危険性が高い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メルカリでの購入は危険性が高い`}</h3>
    <p>{`これらのウイルスは基本的にWindows OSの上にアプリケーションをインストールして動くものである。よって`}<strong parentName="p"><em parentName="strong">{`OS自体が初期化された状態だと、不要なアプリは一切インストールされていないため危険性が低い`}</em></strong>{`。`}</p>
    <p>{`ところがメルカリなどフリマアプリで中古パソコンを見ると初期化後にマイクロソフトオフィスを入れるなど、アプリをインストールしているケースが多く見られる。`}</p>
    <p>{`この時に情報を盗み見るソフトをこっそり入れた場合はどうだろうか？例えばバックグラウンドで入力されたキーを送るプログラム(キーロガープログラムという)を仕込まれると、簡単にIDとパスワード情報が盗まれてしまう。`}</p>
    <p>{`よって`}<strong parentName="p"><em parentName="strong">{`フリマアプリで中古パソコンを購入するときは少なくとも初期化された状態のものにしよう`}</em></strong>{`。`}</p>
    <p>{`もっとも出品者もレビューなどを受けるので、悪意を働く人はほぼいないであろうが。。それでも`}<strong parentName="p"><em parentName="strong">{`保証は無く、梱包は素人でパソコンが傷つく可能性大などリスクは高く、価格も安くないなど悪条件が揃うのでいずれにせよあまりお勧めできない`}</em></strong>{`。`}</p>
    <h3 {...{
      "id": "出品者は更に危険",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%87%BA%E5%93%81%E8%80%85%E3%81%AF%E6%9B%B4%E3%81%AB%E5%8D%B1%E9%99%BA",
        "aria-label": "出品者は更に危険 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`出品者は更に危険`}</h3>
    <p>{`ちなみにの話ではあるが、自分のパソコンを出品する側は細心の注意が必要である。HDDは連続したデータがディスクに書かれているのでフォーマットして初期化したとしても内容が読み取られる可能性が高い。`}</p>
    <p>{`またSSDに関してはHDDほど簡単ではないが、SSD内のデータの割り当て規則から逆算して意味のあるデータを取り出すようなソフトも存在するため、こちらも内容が読み取られる可能性はゼロではない。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`悪意のある人が、メルカリでパソコンで購入して、それを解析するというケースは大いに考えられるので、出品者は細心の注意が必要`}</em></strong>{`である。`}</p>
    <p>{`例えばストレージメーカーのCrucialは`}<a parentName="p" {...{
        "href": "https://www.crucial.jp/support/storage-executive",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Crucial Storage Executive`}</a>{`というデータ消去を完全な形で行えるツールを提供している。このような事をキチンとやっていく必要があるため、`}<strong parentName="p"><em parentName="strong">{`盗まれてよいデータしか入っていないような場合以外はおすすめできない`}</em></strong>{`。`}</p>
    <h2 {...{
      "id": "マイクロソフトオフィスの所有権譲渡禁止に伴うリスク",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%9E%E3%82%A4%E3%82%AF%E3%83%AD%E3%82%BD%E3%83%95%E3%83%88%E3%82%AA%E3%83%95%E3%82%A3%E3%82%B9%E3%81%AE%E6%89%80%E6%9C%89%E6%A8%A9%E8%AD%B2%E6%B8%A1%E7%A6%81%E6%AD%A2%E3%81%AB%E4%BC%B4%E3%81%86%E3%83%AA%E3%82%B9%E3%82%AF",
        "aria-label": "マイクロソフトオフィスの所有権譲渡禁止に伴うリスク permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`マイクロソフトオフィスの所有権譲渡禁止に伴うリスク`}</h2>
    <p>{`セキュリティ問題ではないが掴まされるリスクとしてついでなので一つ挙げておきたい。`}</p>
    <p>{`2021年の10月以降、`}<a parentName="p" {...{
        "href": "https://answers.microsoft.com/ja-jp/msoffice/forum/all/office%e3%81%ae%e8%ad%b2%e6%b8%a1%e3%81%ab/8ed3943e-005c-4ab0-836c-fb7fe30fd945",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Microsoft Officeの譲渡は禁止されている`}</a>{`されたため、中古でそのままMicrosoft Officeを使うことは不可能となった。`}</p>
    <p>{`結果、`}<strong parentName="p"><em parentName="strong">{`譲渡にはアカウントごと譲渡しなければならなくなった`}</em></strong>{`が、アカウントを提供する人は皆無であろうし、既に自分のアカウントを持っている人は他の人が提供するアカウントを使いたくはないだろう。`}</p>
    <p>{`昔のOfficeはプロダクトコードがついていて、それを入力することで譲渡が簡単であったが、Microsoft Office 2019以降はプロダクトコード方式は廃止されている。`}</p>
    <p>{`よって`}<strong parentName="p"><em parentName="strong">{`メルカリなどでOffice搭載となっている製品は購入するとアカウントの譲渡問題で使えないか、あるいは古いMicrosoft Officeか明確なポリシー違反か何か`}</em></strong>{`であり、いずれにしても高リスクである。`}</p>
    <h2 {...{
      "id": "中古パソコンの危険性と回避策のまとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E5%8D%B1%E9%99%BA%E6%80%A7%E3%81%A8%E5%9B%9E%E9%81%BF%E7%AD%96%E3%81%AE%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "中古パソコンの危険性と回避策のまとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`中古パソコンの危険性と回避策のまとめ`}</h2>
    <p>{`最後に今まで話してきたことをまとめておくと次のようになる。`}</p>
    <AccentBox title="中古パソコンの危険性と回避策のまとめ" mdxType="AccentBox">
  <li><span className="bold">OS</span>・・・Windows 10をサポート期間終了後３年程度は使っても問題はない。またWindows 11へのUpgradeはIntelの第８世代以上、Ryzenの2000シリーズ以降が無難。</li>
  <li><span className="bold">ウイルスの危険性</span>・・・メルカリなどフリマアプリはウイルスを簡単に仕込めるため購入する場合は自己責任。信頼のある中古パソコンショップから買うのが無難。また期限切れOSに対して不安ならばAvastなど無料のウイルス対策ソフトを入れる。</li>
  <li><span className="bold">マイクロソフトオフィス</span>・・・信頼のある中古パソコンショップから購入する以外のケースは入れない。</li>
    </AccentBox>
    <h2 {...{
      "id": "中古パソコンのおすすめショップランキング",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%82%B7%E3%83%A7%E3%83%83%E3%83%97%E3%83%A9%E3%83%B3%E3%82%AD%E3%83%B3%E3%82%B0",
        "aria-label": "中古パソコンのおすすめショップランキング permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`中古パソコンのおすすめショップランキング`}</h2>
    <p>{`最後にセキュリティが担保されている優良な中古パソコンショップの中で、`}<strong parentName="p"><em parentName="strong">{`特におすすめできるパソコンショップを２つ紹介`}</em></strong>{`する。いずれも`}<strong parentName="p"><em parentName="strong">{`価格、品質、保証面で水準を満たし`}</em></strong>{`、かつ十分な取引量を備えているため事業撤退の可能性も低いだろう。`}</p>
    <h3 {...{
      "id": "１位：Qualit---ビジネスクオリティの中古パソコン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%91%E4%BD%8D%EF%BC%9AQualit---%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E3%82%AF%E3%82%AA%E3%83%AA%E3%83%86%E3%82%A3%E3%81%AE%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3",
        "aria-label": "１位：Qualit   ビジネスクオリティの中古パソコン permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`１位：Qualit - ビジネスクオリティの中古パソコン`}</h3>
    <Image {...props} name="qualit" alt="Qualit" mdxType="Image" />
    <p>{`まず最もおすすめしたいサイトがこのQualitである。他の中古パソコンと比べ圧倒的なコストパフォーマンス、クオリティ、パソコン品質のムラの少なさを誇る。`}</p>
    <p>{`横河レンタ・リースという法人向けとして有名なパソコンのレンタルサービスがあり、私が勤めている会社でも利用しているのだが、`}<strong parentName="p"><em parentName="strong">{`レンタル期限を終えて返却されたパソコンを再整備して売りに出している`}</em></strong>{`のである。`}</p>
    <p>{`安値で仕入れ、高値で売るという多くの中古パソコンショップとは`}<strong parentName="p"><em parentName="strong">{`ビジネススキームで一線を画しており、これが圧倒的な価格競争力を実現`}</em></strong>{`している。`}</p>
    <p>{`更に法人向けの厳しいビジネス要求に応えられるよう`}<strong parentName="p"><em parentName="strong">{`バッテリーチェックを含め高いレベルの点検・整備を行なっている`}</em></strong>{`ので文句の付けようが無い。`}</p>
    <p>{`法人用に使われているという事実は、個人で無理な使われ方をしたパソコンが来ないという点でも安心材料である。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="https://px.a8.net/svt/ejp?a8mat=3NPOM7+F0LP5E+50H8+5YJRM" target="_blank" rel="nofollow noopener">Qualit</a>
  <img border="0" width="1" height="1" src="https://www12.a8.net/0.gif?a8mat=3NPOM7+F0LP5E+50H8+5YJRM" alt="" />
    </SmallBox>
    <h3 {...{
      "id": "２位：PC-WRAP---３年保証と高いコストパフォーマンス",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%92%E4%BD%8D%EF%BC%9APC-WRAP---%EF%BC%93%E5%B9%B4%E4%BF%9D%E8%A8%BC%E3%81%A8%E9%AB%98%E3%81%84%E3%82%B3%E3%82%B9%E3%83%88%E3%83%91%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%B3%E3%82%B9",
        "aria-label": "２位：PC WRAP   ３年保証と高いコストパフォーマンス permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`２位：PC WRAP - ３年保証と高いコストパフォーマンス`}</h3>
    <Image {...props} name="pcwrap" alt="PCWrap" mdxType="Image" />
    <p>{`東証一部上場のIT企業であるShiftのグループ会社である株式会社エスエヌシーが運営するパソコンショップで、大阪を主な拠点とする。`}</p>
    <p>{`年に６万台程度パソコンを再生し、トップレベルの取引量を誇る。`}</p>
    <p>{`取引量と効率的な検品のオペレーションからくる`}<strong parentName="p"><em parentName="strong">{`コストパフォーマンスに加え、３年保証、当日発送など購入者にとっての安心材料も豊富`}</em></strong>{`である。`}</p>
    <p>{`尚正式サポートの範囲外のCPUを搭載したWindows 11PCも販売されているが、その点は販売側も認識しているため十分な動作確認は行なっている。ともあれ購入するならば自己責任である。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://px.a8.net/svt/ejp?a8mat=3NPOM7+FVK8LU+4U56+5YRHE" target="_blank" rel="nofollow noopener">PC WRAP</a>
      <img border="0" width="1" height="1" src="https://www13.a8.net/0.gif?a8mat=3NPOM7+FVK8LU+4U56+5YRHE" alt="" />
    </SmallBox>
    <h3 {...{
      "id": "その他",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%81%9D%E3%81%AE%E4%BB%96",
        "aria-label": "その他 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`その他`}</h3>
    <p>{`その他CPU性能の簡単検索ツールとともに下記にておすすめショップを紹介しているので確認してほしい。`}</p>
    <Card {...props} title="中古パソコンおすすめショップランキング" description="品質とコスパに優れた中古パソコンのおすすめショップをランキング形式で紹介します。" image="cusedshop" path="/used-shop/" mdxType="Card" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      